var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("../assets/image/30.png")}}),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('task')))])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"watch-title"},[_vm._v(_vm._s(_vm.$t('gksp')))]),_c('div',{staticClass:"watch-list"},[_c('div',{staticClass:"watch-left"},[_c('img',{staticClass:"watch-logo",attrs:{"src":require("../assets/image/31.png")}}),_c('div',{staticClass:"text"},[_c('span',{staticClass:"watch-text"},[_vm._v(_vm._s(_vm.$t('gkyfz')))]),_vm._m(0)])]),_vm._m(1)]),_c('div',{staticClass:"watch-title"},[_vm._v(_vm._s(_vm.$t('gksp')))]),_c('div',{staticClass:"watch-list"},[_c('div',{staticClass:"watch-left"},[_c('img',{staticClass:"watch-logo",attrs:{"src":require("../assets/image/31.png")}}),_c('div',{staticClass:"text"},[_c('span',{staticClass:"watch-text"},[_vm._v(_vm._s(_vm.$t('gkyfz')))]),_vm._m(2)])]),_vm._m(3)]),_c('div',{staticClass:"watch-list"},[_c('div',{staticClass:"watch-left"},[_c('img',{staticClass:"watch-logo",attrs:{"src":require("../assets/image/31.png")}}),_c('div',{staticClass:"text"},[_c('span',{staticClass:"watch-text"},[_vm._v(_vm._s(_vm.$t('gkyfz')))]),_vm._m(4)])]),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"watch-add"},[_c('img',{attrs:{"src":require("../assets/image/3.png")}}),_c('span',[_vm._v("+1")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"watch-right"},[_c('img',{attrs:{"src":require("../assets/image/right.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"watch-add"},[_c('img',{attrs:{"src":require("../assets/image/3.png")}}),_c('span',[_vm._v("+1")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"watch-right"},[_c('img',{attrs:{"src":require("../assets/image/right.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"watch-add"},[_c('img',{attrs:{"src":require("../assets/image/3.png")}}),_c('span',[_vm._v("+1")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"watch-right"},[_c('img',{attrs:{"src":require("../assets/image/right.png"),"alt":""}})])
}]

export { render, staticRenderFns }