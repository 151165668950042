<template>
  <div class="wrap">
    <div class="title">{{ $t("yqhy") }}</div>
    <div class="sub-title">{{ $t("yqhy_tips") }}</div>
    <div class="explain">
      <img class="add-logo" src="../assets/image/28.png" />
      <div class="explain-text">
        <div class="name">{{ $t("yqhy_bind") }}</div>
        <div class="num">
          <b class="num-text">{{ $t("yqhy_bind_tips") }}</b>
          <img src="../assets/image/8.png" />
          <b>+200abs</b>
        </div>
      </div>
    </div>
    <div class="binding">
      <div class="binding-button" @click="bindingFriend()">
        {{ $t("bind_btn") }}
      </div>
    </div>
    <div class="list-text">{{ $t("friend_list") }}</div>
    <div class="friend-list">
      <div class="friend" v-for="(item, index) in lowerList" :key="index">
        {{ item.substr(0, 6) }}......{{ item.substr(-6) }}
      </div>
    </div>
    <div class="invite">
      <div class="invite-button" @click="copyTeleHandle">{{ $t("yqnhy") }}</div>
      <div class="copy-button" @click="copyHandle">
        <img src="../assets/image/29.png" alt="" />
      </div>
    </div>
    <!-- <div style="color: #fff;">{{ userId }}{{ user && user.username }}</div> -->
  </div>
</template>
<script>
import { get_lower_list } from "@/api";
import { Notify, Toast } from "vant";
export default {
  data() {
    return {
      lowerList: [],
      filter: {
        pages: 1,
        limit: 20,
      },
      userId: "",
      user: "",
    };
  },
  mounted() {
    this.getChildrenList();
  },
  computed: {
    selectAddress() {
      return this.$store.state.user.accountAddress;
    },
  },
  methods: {
    bindingFriend() {
      this.$router.push({
        path: "/binding", //跳转的路由
        // query:{    //跳转路由的参数
        //     type:type    //前面的type是参数的名称//后面的type是点击产品时传过来的型号 这里也就是m320
        // }
      });
    },
    getChildrenList() {
      get_lower_list({
        address: this.selectAddress,
        ...this.filter,
      }).then((res) => {
        this.lowerList = res.data;
      });
    },
    copyHandle() {
      let domUrl = document.createElement("input");
      domUrl.value =
        location.origin +
        "/#" +
        location.pathname +
        "home?address=" +
        this.selectAddress;

      domUrl.id = "creatDom";

      document.body.appendChild(domUrl);

      domUrl.select(); // 选择对象

      document.execCommand("Copy"); // 执行浏览器复制命令

      let creatDom = document.getElementById("creatDom");

      creatDom.parentNode.removeChild(creatDom);
      Notify({
        type: "success",
        message: this.$t("copy_success"),
      });
    },

    copyTeleHandle() {
      let domUrl = document.createElement("input");
      domUrl.value =
        "https://t.me/MintGameTestBot/mintgame?startapp=" + this.selectAddress;

      domUrl.id = "creatDom";

      document.body.appendChild(domUrl);

      domUrl.select(); // 选择对象

      document.execCommand("Copy"); // 执行浏览器复制命令

      let creatDom = document.getElementById("creatDom");

      creatDom.parentNode.removeChild(creatDom);
      Notify({
        type: "success",
        message: this.$t("copy_success"),
      });
    },
  },
};
</script>

<style scoped lang="scss">
.wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 40px 0 40px;
}

.title {
  color: #ffffff;
  margin: 0 auto;
  margin-top: 207px;
  font-size: 64px;
  font-weight: bold;
}

.sub-title {
  color: #979690;
  margin: 50px auto;

  font-size: 34px;
}

.explain {
  width: 1000px;
  height: 180px;
  background-color: #1d1d1c;
  border-radius: 60px;
  display: flex;
}

.add-logo {
  width: 114px;
  height: 114px;
  margin: 33px 36px;
}

.explain-text {
  padding: 45px 0 35px 0;
}

.name {
  height: 34px;
  line-height: 34px;
  font-size: 34px;
  color: #ffffff;
}

.num {
  height: 30px;
  line-height: 30px;
  font-size: 30px;
  margin-top: 24px;
  display: flex;
  align-items: center;
}

.num-text {
  color: #ffffff;
  font-weight: normal;
}

.num:last-child {
  color: #db9636;
  font-weight: normal;
}

.num img {
  width: 40px;
  height: 37px;
  margin: 0 12px 0 16px;
  display: inline-block;
}

.binding {
  width: 1000px;
  height: 180px;
  background-color: #1d1d1c;
  border-radius: 60px;
  margin-top: 50px;
}

.binding-button {
  width: 940px;
  height: 120px;
  line-height: 120px;
  margin: 30px;
  text-align: center;
  background-color: #db9636;
  border-radius: 40px;
  color: #ffffff;
  font-size: 36px;
}

.list-text {
  margin-top: 89px;
  color: #ffffff;
  font-size: 36px;
  font-weight: bold;
}

.friend-list {
  margin-top: 50px;
  width: 1000px;
  background-color: #1d1d1c;
  border-radius: 60px;
}

.friend {
  margin: 36px 61px 0 61px;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 34px;
  margin-left: 47px;
}

.friend:last-child {
  margin-bottom: 36px;
}

.invite {
  display: flex;
  justify-content: space-between;
  margin-top: 226px;
  padding-bottom: 100px;
}

.invite-button {
  width: 842px;
  height: 130px;
  line-height: 130px;
  text-align: center;
  background-color: #db9636;
  font-size: 36px;
  color: #ffffff;
  border-radius: 40px;
}

.copy-button {
  width: 140px;
  height: 130px;
  line-height: 130px;
  text-align: center;
  background-color: #db9636;
  color: #ffffff;
  border-radius: 40px;
}

.copy-button img {
  width: 50px;
  height: 50px;
  vertical-align: middle;
  /* 垂直居中 */
}
</style>
