<template>
    <div class="wrap">
        <div class="banner">
            <img src="../assets/image/30.png"/>
            <span class="title">{{ $t('task') }}</span>
        </div>
        <div class="content">
            <div class="watch-title">{{ $t('gksp') }}</div>
            <div class="watch-list">
                <div class="watch-left">
                    <img class="watch-logo" src="../assets/image/31.png" />
                    <div class="text">
                        <span class="watch-text">{{ $t('gkyfz') }}</span>
                        <div class="watch-add">
                            <img src="../assets/image/3.png" />
                            <span>+1</span>
                        </div>
                    </div>
                </div>
                <div class="watch-right">
                    <img src="../assets/image/right.png" alt="" />
                </div>
            </div>
            <div class="watch-title">{{ $t('gksp') }}</div>
            <div class="watch-list">
                <div class="watch-left">
                    <img class="watch-logo" src="../assets/image/31.png" />
                    <div class="text">
                        <span class="watch-text">{{ $t('gkyfz') }}</span>
                        <div class="watch-add">
                            <img src="../assets/image/3.png" />
                            <span>+1</span>
                        </div>
                    </div>
                </div>
                <div class="watch-right">
                    <img src="../assets/image/right.png" alt="" />
                </div>
            </div>
            <div class="watch-list">
                <div class="watch-left">
                    <img class="watch-logo" src="../assets/image/31.png" />
                    <div class="text">
                        <span class="watch-text">{{ $t('gkyfz') }}</span>
                        <div class="watch-add">
                            <img src="../assets/image/3.png" />
                            <span>+1</span>
                        </div>
                    </div>
                </div>
                <div class="watch-right">
                    <img src="../assets/image/right.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    
}
</script>
<style lang="scss" scoped>
.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.banner {
    display: flex;
    justify-content: center;
    position: relative;
}

.banner img {
    margin:  14px 0;
    height: 520px;
}

.title {
    position: absolute;
    bottom: 0;
    color: #FFFFFF;
    font-size: 64px;
    font-weight: bold;
}

.content {
    padding: 0 40px;
}

.watch-title {
    margin-top: 103px;
    color: #FFFFFF;
    font-size: 36px;
    font-weight: bold;
}

.watch-list {
    width: 1000px;
    height: 180px;
    background-color: #222120;
    border-radius: 60px;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}

.watch-left {
    display: flex;
}

.watch-right {
    display: flex;
    align-items: center;
    margin-right: 47px;
}

.watch-logo {
    width: 112px;
    height: 112px;
    margin: 34px 36px;
}

.text {
    display: flex;   
    flex-direction: column;
    padding-top: 45px;
}

.watch-text {
    color: #FFFFFF;
    font-size: 34px;
    height: 34px;
    line-height: 34px;
}

.watch-add {
    margin-top: 29px;
    height: 37px;
}

.watch-add img {
    width: 40px;
    height: 37px;
}

.watch-add span {
    color: #DB9636;
    font-size: 30px;
}
</style>