<template>
  <div class="wrap">
    <div class="title1">{{ $t('xmxq') }}</div>
    <div class="list">
      <div class="list-left">
        <img :src="details.Avatar" alt="" />
        <div class="info">
          <span class="name">{{ details.Symbol }}</span>
          <span class="num"><b>{{ $t('zcc') }}</b> <b>{{ details.Output }}</b></span>
        </div>
      </div>
      <div class="list-right">
        <div class="liked" :class="{ active: details.IsLiked == 1 }" @click="toggleLike(details.IsLiked)">
          <img src="../assets/image/15.png" alt="" v-if="details.IsLiked == 1" />
          <img src="../assets/image/16.png" alt="" v-else />
          <span>{{ $t('zan') }} {{ details.Liked }}</span>
        </div>
      </div>
    </div>
    <div class="link">
      <div class="link-list">
        <a :href="details.Website">
          <img src="../assets/image/18.png" />
          <div>{{ $t('website') }}</div>
        </a>

      </div>
      <div class="link-list">
        <a :href="details.Twitter">
          <img src="../assets/image/19.png" />
          <div>{{ $t('twitter') }}</div>
        </a>
      </div>
      <div class="link-list">
        <a :href="details.Telegram">
          <img src="../assets/image/20.png" />
          <div>{{ $t('telegram') }}</div>
        </a>
      </div>
    </div>
    <div class="token-info">
      <div class="info-list">
        <span>{{ $t('coin_total') }}</span>
        <span>{{ details.Supply }}</span>
      </div>
      <div class="info-list">
        <span>{{ $t('coin_price') }}</span>
        <span>{{ details.Price }}</span>
      </div>
      <div class="info-list">
        <span>{{ $t('coin_pool') }}</span>
        <span>{{ details.Pairs }}</span>
      </div>
    </div>
    <div class="title2">{{ $t('remark') }}</div>
    <div class="detail">
      <div v-html="details.Details"></div>
    </div>
  </div>
</template>
<script>
import { get_pool_detail, like_pool, cancel_like_pool } from "@/api";
export default {
  data() {
    return {
      id: "",
      current: 1,
      details: {},
    };
  },
  computed: {
    selectAddress() {
      return this.$store.state.user.accountAddress;
    },
  },
  mounted() {
    this.id = this.$route.query.id || "";
    this.getDetail();
  },
  methods: {
    getDetail() {
      get_pool_detail({
        pool_id: this.id,
        address: this.selectAddress,
      })
        .then((res) => {
          this.details = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async toggleLike(IsLiked) {
      let params = {
        pool_id: this.id,
        address: this.selectAddress,
      };
      if (IsLiked == 1) {
        await cancel_like_pool(params);
      } else {
        await like_pool(params);
      }
      this.getDetail();
    },
  },
};
</script>

<style scoped lang="scss">
.wrap {
  padding: 40px;
}

.title1 {
  color: #ffffff;
  margin-top: 148px;
  font-size: 36px;
}

.list {
  width: 1000px;
  height: 220px;
  background-color: #1e1e1d;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  margin: 55px 0 30px 0;
 
}

.list-left {
  display: flex;
}

.list-left img {
  width: 120px;
  height: 120px;
  margin: 52px 37px 48px 61px;
  border-radius: 50%;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 67px 0 69px 0;
}

.info span {
  color: #ffffff;
}

.name {
  font-size: 34px;
  height: 33px;
  line-height: 33px;
  font-weight: bold;
  vertical-align: top;
}

.num {
  font-size: 32px;
  height: 31px;
  line-height: 31px;
  vertical-align: baseline;
}

.num b:first-child {
  color: #979690;
  font-weight: normal;
}

.num b:last-child {
  color: #e28928;
  font-weight: normal;
}

.list-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 35px 41px 38px 0;
}

.liked {
  width: 250px;
  height: 82px;
  line-height: 82px;
  background-color: #353432;
  border-radius: 40px;
  display: flex;
}

.liked img {
  width: 46px;
  height: 44px;
  margin: 16px 19px 22px 37px;
}

.liked span {
  font-size: 28px;
  color: #a2a2a1;
}

.list-right .active {
  background-color: #382c1f;
}

.list-right .active span {
  color: #c28928;
}

.link {
  width: 1000px;
  height: 240px;
  background-color: #1f1e1d;
  display: flex;
  justify-content: space-evenly;
  border-radius: 30px;
  align-items: center;
}

.link-list {

  a {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
}

.link-list img {
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
}

.link-list div {
  color: #7a756c;
  font-size: 30px;
}

.token-info {
  width: 1000px;
  height: 300px;
  background-color: #1f1e1d;
  border-radius: 60px;
  margin-top: 30px;
  overflow: hidden;
}

.info-list {
  line-height: 32px;
  height: 32px;
  font-size: 34px;
  color: #ffffff;
  margin: 0 0 48px 52px;
}

.token-info>div:first-child {
  margin-top: 59px;
}

.token-info>div:last-child {
  margin-bottom: 0;
}

.info-list span {
  padding-right: 40px;
}

.title2 {
  color: #ffffff;
  margin-top: 100px;
  font-size: 36px;
}

.detail {
  width: 880px;
  padding: 60px;
  background-color: #1f1e1d;
  border-radius: 60px;
  margin-top: 57px;
  color: #979690;
  font-size: 34px;
  line-height: 72px;
}
</style>
