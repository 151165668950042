<template>
  <div class="full-page">
    <div class="main">
      <router-view />
    </div>
    <div class="footer">
      <div
        class="item"
        :class="{ active: activeMenu == '/home' }"
        @click="routeHandle('/home')"
      >
        <img
          v-if="activeMenu == '/home'"
          src="@/assets/image/5_on.png"
          alt=""
        />
        <img v-else src="@/assets/image/5.png" alt="" />
        <span>{{ $t("jinchanzi") }}</span>
      </div>
      <div
        class="item"
        :class="{ active: activeMenu == '/rank' }"
        @click="routeHandle('/rank')"
      >
        <img
          v-if="activeMenu == '/rank'"
          src="@/assets/image/6_on.png"
          alt=""
        />
        <img v-else src="@/assets/image/6.png" alt="" />
        <span>{{ $t("ranking") }}</span>
      </div>
      <div
        class="item active"
        style="background-color: #030200"
        @click="routeHandle('/airdrop')"
      >
        <img src="@/assets/image/8.png" alt="" />
        <span>{{ $t("airdrop") }}</span>
      </div>
      <div
        class="item"
        :class="{ active: activeMenu == '/user' }"
        @click="routeHandle('/user')"
      >
        <img
          v-if="activeMenu == '/user'"
          src="@/assets/image/9_on.png"
          alt=""
        />
        <img v-else src="@/assets/image/9.png" alt="" />
        <span>{{ $t("friend") }}</span>
      </div>
      <div
        class="item"
        :class="{ active: activeMenu == '/task' }"
        @click="routeHandle('/task')"
      >
        <img
          v-if="activeMenu == '/task'"
          src="@/assets/image/11_on.png"
          alt=""
        />
        <img v-else src="@/assets/image/11.png" alt="" />
        <span>{{ $t("quest") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  watch: {},
  computed: {
    activeMenu() {
      const route = this.$route;
      const { path } = route;
      return path;
    },
  },
  data() {
    return {};
  },
  async created() {},
  methods: {
    routeHandle(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.full-page {
  display: flex;
  flex-direction: column;
  .main {
    height: 1px;
    flex: 1;
    overflow-y: auto;
  }
  .footer {
    height: 142px;
    background: rgba($color: #fff, $alpha: 0.05);
    display: flex;
    align-items: center;
    justify-content: space-around;
    .item {
      width: 222px;
      height: 122px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #51504c;
      font-size: 24px;
      border-radius: 20px;
      &.active {
        color: #db9636;
      }
      img {
        width: 70px;
        height: 70px;
      }
    }
  }
}
</style>
