<template>
  <div class="wrap">
    <div class="header">
      <div class="coinbox" @click="mainDetail()">
        <img :src="reward[0]" alt="" />
        <span class="line"></span>
        <span class="coin">{{ reward[1] }}</span>
        <span class="num">{{ reward[2] }}</span>
      </div>
      <div class="address">
        {{ selectAddress.substr(0, 6) }}...{{ selectAddress.substr(-4) }}
      </div>
      <div class="language" @click="changeLanguage">
        <span>{{ $i18n.locale.toUpperCase() }}</span>
        <img src="@/assets/image/22.png" alt="" />
      </div>
    </div>
    <div class="main">
      <div class="title">{{ $t("title") }}</div>
      <div class="klistbox">
        <div class="list">
          <template v-for="(item, index) in tokens">
            <div
              class="item"
              v-if="true"
              :key="index"
              :class="{ active: current == index }"
              @click="selectTokenHandle(item, index)"
            >
              <div class="img">
                <img :src="item.Avatar" alt="" />
              </div>

              <div class="coin-name">{{ item.Symbol }}</div>
            </div>
          </template>
        </div>
        <div class="dotbox">
          <template v-for="(item, index) in tokens">
            <span :class="{ active: current == index }"></span>
          </template>
        </div>
      </div>
      <div class="result">
        <div class="num">
          {{ $t("kechanchu") }}{{ selectTokenInfo.Output }}
          {{ selectToken.Symbol }}
        </div>
        <div class="des">
          {{ $t("lasttime") }} <span>{{ lastTime }}</span>
        </div>
        <div class="des">
          {{ $t("kewa") }}<span>{{ rest }}</span
          >{{ $t("kewa_unit") }}
        </div>
        <!-- <div class="des">
            可提取<span>{{ unextractedReward }}</span>
          </div>
          <div class="des">
            已提取<span>{{ extractedReward }}</span>
          </div>
          <div class="" style="color: #fff;" @click="drawReward">提取</div> -->
      </div>
      <div class="playbtn">
        <img src="@/assets/image/25.png" alt="" />
        <!-- // 开始 -->
        <div @click="drawHandle"></div>
      </div>
      <div class="active-btn" v-if="!isActive" @click="activeAddressHandle">
        {{ $t("jihuo_btn") }}
      </div>
    </div>
  </div>
</template>

<script>
import { Notify, Toast } from "vant";
import { getUrlKey } from "@/utils";
import {
  selectAddress,
  getActiveFee,
  addressIsActive,
  activeAddress,
} from "@/contract";
import web3 from "web3";
import {
  get_pool_list_home,
  get_pool_info,
  get_mint_rest,
  mint_token,
  get_main_reward,
  binding_parent,
} from "@/api/index";

import { toWei, fromWei } from "@/utils/utils";
let timer;
export default {
  data() {
    return {
      reward: 0,
      isApprove: false, // 是否授权
      activeFee: 0, // 激活费用
      isActive: false, //是否激活
      current: 0,

      tokens: [], // 代币列表
      selectToken: "", // 选中的代币
      selectTokenInfo: "", //选中代币详情
      rest: 0, // 剩余次数
      lastTime: "00 : 00 : 00",

      invite: "",
    };
  },
  computed: {
    selectAddress() {
      return this.$store.state.user.accountAddress;
    },
  },
  mounted() {
    this.invite =
      getUrlKey("address") || localStorage.getItem("startParam") || "";
    console.log(localStorage.getItem("startParam"), "home startparam");
    this.getCoinTotal();
    this.checkAddressIsActive();
    this.getTokenList();
  },
  component: {
    Notify,
  },
  methods: {
    changeLanguage() {
      let lan = localStorage.getItem("locale");
      let locale;
      if (lan == "en") {
        locale = "zh";
      } else {
        locale = "en";
      }
      this.$i18n.locale = locale;
      localStorage.setItem("locale", locale);
    },
    // 获取平台币
    getCoinTotal() {
      get_main_reward({
        address: this.selectAddress,
      }).then((res) => {
        this.reward = res.data;
      });
    },
    // 获取激活费用
    getActiveFeeHandle() {
      getActiveFee().then((res) => {
        this.activeFee = res;
      });
    },
    // 查看是否激活
    checkAddressIsActive() {
      addressIsActive().then((res) => {
        this.isActive = res;
        if (!this.isActive) {
          this.getActiveFeeHandle();
        }
      });
    },
    // 激活
    activeAddressHandle() {
      if (!this.selectAddress) {
        Notify({
          type: "danger",
          message: this.$t("meta_warning"),
        });
        return;
      }
      Toast.loading({
        duration: 0,
        forbidClick: true,
      });
      activeAddress(this.activeFee)
        .then((res) => {
          this.checkAddressIsActive();
          Toast.success(this.$t("success"));
          if (this.invite) {
            binding_parent({
              parent: this.invite,
              address: this.select,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          Toast.clear();
        });
    },

    mainDetail() {
      this.$router.push({
        path: "/main_detail", //跳转的路由
        // query:{    //跳转路由的参数
        //     type:type    //前面的type是参数的名称//后面的type是点击产品时传过来的型号 这里也就是m320
        // }
      });
    },
    // 获取代币列表
    getTokenList() {
      get_pool_list_home().then((res) => {
        this.tokens = res.data;
        this.selectToken = this.tokens[0];
        this.getTokenInfo();
      });
    },
    getTokenInfo() {
      get_pool_info({
        pool_id: this.selectToken.Id,
      }).then((res) => {
        this.selectTokenInfo = res.data;
        this.getMintRest();
        this.timeCountDown();
      });
    },
    selectTokenHandle(item, index) {
      this.selectToken = item;
      this.current = index;
      this.getTokenInfo();
    },
    // 获取当日挖矿次数
    getMintRest() {
      get_mint_rest({
        pool_id: this.selectToken.Id,
        address: this.selectAddress,
      }).then((res) => {
        this.rest = res.data;
      });
    },
    getTimestamp() {
      return new Date().getTime() / 1000;
    },
    // 倒计时
    timeCountDown() {
      let times = this.selectTokenInfo.Deadline - this.getTimestamp();
      if (times > 0) {
        let h = parseInt(times / 60 / 60);
        h = h < 10 ? "0" + h : h;
        let m = parseInt((times / 60) % 60);
        m = m < 10 ? "0" + m : m;
        let s = parseInt(times % 60);
        s = s < 10 ? "0" + s : s;
        this.lastTime = `${h} : ${m} : ${s}`;
        timer = setTimeout(() => {
          this.timeCountDown();
        }, 1000);
      } else {
        clearTimeout(timer);
      }
    },
    // 开始挖矿
    drawHandle() {
      if (!this.isActive) {
        Notify({
          type: "danger",
          message: this.$t("jihuo_warning1"),
        });
      } else if (this.rest <= 0) {
        Notify({
          type: "danger",
          message: this.$t("jihuo_warning2"),
        });
      } else {
        Toast.loading({
          duration: 0,
          forbidClick: true,
        });
        mint_token({
          pool_id: this.selectToken.Id,
          address: this.selectAddress,
        }).then((res) => {
          Toast.clear();
          Notify({
            type: "success",
            message: this.$t("success"),
          });
          this.getMintRest();
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  padding: 60px 40px;
  display: flex;
  justify-content: space-between;

  .coinbox {
    display: flex;
    align-items: center;
    padding: 0 30px;
    height: 85px;
    font-size: 40px;
    color: #db9636;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 41px;
    border: 1px solid rgba(255, 255, 255, 0.3);

    img {
      width: 50px;
      border-radius: 50%;
    }

    .line {
      width: 1px;
      height: 48px;
      background: rgba(255, 255, 255, 0.4);
      margin: 0 30px;
    }

    .coin {
      color: #fff;
      margin-right: 30px;
    }
  }

  .address {
    flex: 1;
    height: 85px;
    text-align: center;
    border: 3px solid #eac765;
    color: #fff;
    font-size: 32px;
    border-radius: 41px;
    line-height: 85px;
    margin: 0 33px;
    box-shadow: 0 0 10px 0 rgba($color: #eac765, $alpha: 0.7);
  }

  .language {
    display: flex;
    color: #fff;
    font-size: 32px;
    align-items: center;

    img {
      width: 30px;
      height: 26px;
      margin-left: 18px;
    }
  }
}

.main {
  background: linear-gradient(0deg, #050505, #1b1917);
  box-shadow: 0px -10px 160px 0px rgba(234, 199, 101, 0.5);
  border-radius: 100px 100px 0px 0px;
  border-top: 3px solid #e18f2d;
  padding: 42px 0;
  flex: 1;
  height: 1px;

  .title {
    font-size: 60px;
    // font-style: italic;
    color: #fff;
    text-align: center;
    font-family: "pingfang";
    font-weight: bold;
  }

  .klistbox {
    padding: 0 40px;
    margin-top: 20px;

    .list {
      display: flex;
      overflow-x: auto;
      scrollbar-width: none;

      .item {
        padding: 20px;
        box-sizing: border-box;
        display: inline-block;
        width: 320px;
        height: 340px;
        margin-right: 20px;
        background: linear-gradient(
          0deg,
          rgba(#eac765, 0.08),
          rgba(#ffffff, 0.08)
        );
        border-radius: 20px;
        color: #db9636;

        &.active {
          background: linear-gradient(
            0deg,
            rgba(219, 150, 54, 0.3),
            rgba(234, 199, 101, 0.3)
          );
          border: 5px solid rgba(234, 199, 101, 0.7);
          color: #fff;
        }

        .img {
          width: 280px;
          height: 200px;
          background-color: #fff;
          border-radius: 14px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 100%;
            max-height: 90%;
            border-radius: 50%;
          }
        }

        .coin-name {
          padding: 43px 0 0 0;
          text-align: center;
          font-weight: bold;
        }
      }
    }

    .dotbox {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      span {
        width: 20px;
        height: 10px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 5px;
        margin: 0 10px;

        &.active {
          background: #db9636;
        }
      }
    }
  }

  .result {
    padding: 44px;
    margin-top: 59px;
    background: linear-gradient(
      to left,
      rgba(#eac765, 0.01),
      rgba(#eac765, 0.08),
      rgba(#eac765, 0.01)
    );

    .num {
      color: #f6c15e;
      font-weight: bold;
      font-size: 50px;
      display: flex;
      align-items: center;
      margin-bottom: 38px;
      justify-content: center;

      &::before {
        content: "";
        width: 70px;
        height: 52px;
        display: block;
        background-image: url(@/assets/image/23.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-right: 10px;
        margin-top: 8px;
      }
    }

    .des {
      color: #db9636;
      font-size: 40px;
      text-align: center;

      span {
        color: #fff;
        margin: 0 10px;
      }
    }
  }

  .playbtn {
    padding: 57px;
    text-align: center;
    position: relative;

    img {
      width: 640px;
    }

    & > div {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .active-btn {
    width: 1000px;
    height: 120px;
    line-height: 120px;
    margin: 40px;
    text-align: center;
    background-color: #db9636;
    border-radius: 40px;
    color: #ffffff;
    font-size: 36px;
  }
}
</style>
