<template>
  <div class="full-page wrap">
    <div class="banner">
      <img src="../assets/image/13.png" />
      <div class="nav">
        <button :class="{ active: current === 1 }" @click="current = 1">
          {{ $t('kc_rank') }}
        </button>
        <button :class="{ active: current === 2 }" @click="current = 2">
          {{ $t('yzxm_rank') }}
        </button>
        <button :class="{ active: current === 3 }" @click="current = 3">
          {{ $t('yzsq_rank') }}
        </button>
      </div>
    </div>
    <div class="pool">
      <template v-if="list.length">
        <template v-for="(item, index) in list">
          <div class="list" @click="rouetDetail(item.Id)" v-if="current == 1" :key="index">
            <div class="list-left">
              <img :src="item.Avatar" alt="" />
              <div class="info">
                <span class="name">{{ item.Symbol }}</span>
                <span class="num"><b>{{ $t('chanchu') }}</b> <b>{{ item.Output }}</b></span>
              </div>
            </div>
            <div class="list-right">
              <div class="liked" :class="{ active: item.IsLiked == 1 }"
                @click.prevent.stop="toggleLike(item.Id, item.IsLiked)">
                <img src="../assets/image/15.png" alt="" v-if="item.IsLiked == 1" />
                <img src="../assets/image/16.png" alt="" v-else />
                <span>{{ $t('zan') }} {{ item.Liked }}</span>
              </div>
              <div class="hot">
                <img src="../assets/image/17.png" alt="" />
                <span>{{ item.Popular }}</span>
              </div>
            </div>
          </div>
          <div class="list" @click="rouetDetail(item.Id)" v-if="current == 2" :key="index">
            <div class="list-left">
              <img :src="item.Avatar" alt="" />
              <div class="info">
                <span class="name">{{ item.Symbol }}</span>
                <span class="num"><b>{{ $t('totai') }}</b> <b>{{ item.Supply }}</b></span>
              </div>
            </div>
            <div class="list-right">
              <div class="liked" :class="{ active: item.IsLiked == 1 }"
                @click.prevent.stop="toggleLike(item.Id, item.IsLiked)">
                <img src="../assets/image/15.png" alt="" v-if="item.IsLiked == 1" />
                <img src="../assets/image/16.png" alt="" v-else />
                <span>{{ $t('zan') }} {{ item.Liked }}</span>
              </div>
              <div class="hot">
                <img src="../assets/image/17.png" alt="" />
                <span>{{ item.Popular }}</span>
              </div>
            </div>
          </div>
          <div class="list" @click="rouetDetail(item.Id)" v-if="current == 3" :key="index">
            <div class="list-left">
              <img :src="item.Avatar" alt="" />
              <div class="info">
                <span class="name">{{ item.Name }}</span>
                <span class="num"><b>{{ $t('sqrs') }}</b> <b>{{ item.People }}</b></span>
              </div>
            </div>
            <div class="list-right">
              <div class="liked" :class="{ active: item.IsLiked == 1 }"
                @click.prevent.stop="toggleLike(item.Id, item.IsLiked)">
                <img src="../assets/image/15.png" alt="" v-if="item.IsLiked == 1" />
                <img src="../assets/image/16.png" alt="" v-else />
                <span>{{ $t('zan') }} {{ item.Liked }}</span>
              </div>
              <div class="hot">
                <img src="../assets/image/17.png" alt="" />
                <span>{{ item.Popular }}</span>
              </div>
            </div>
          </div>
        </template>
      </template>
      <template v-else>
        <div class="nohint">{{ $t('nodata') }}</div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  get_pool_list,
  like_pool,
  cancel_like_pool,
  get_item_list,
  like_item,
  cancel_like_item,
  get_team_list,
  like_team,
  cancel_like_team,
} from "@/api";
export default {
  data() {
    return {
      current: 1,
      list: [],
    };
  },
  computed: {
    selectAddress() {
      return this.$store.state.user.accountAddress;
    },
  },
  watch: {
    current(val) {
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      let list = [];
      if (this.current == 1) {
        let { data } = await get_pool_list({ address: this.selectAddress });
        list = data;
      }
      if (this.current == 2) {
        let { data } = await get_item_list({ address: this.selectAddress });
        list = data;
      }
      if (this.current == 3) {
        let { data } = await get_team_list({ address: this.selectAddress });
        list = data;
      }
      this.list = list;
    },

    async toggleLike(id, IsLiked) {
      let params = {
        address: this.selectAddress,
      };
      if (this.current == 1) {
        params.pool_id = id
        if (IsLiked == 1) {
          await cancel_like_pool(params);
        } else {
          await like_pool(params);
        }
      }
      if (this.current == 2) {
        params.item_id = id;
        if (IsLiked == 1) {
          await cancel_like_item(params);
        } else {
          await like_item(params);
        }
      }
      if (this.current == 3) {
        params.team_id = id;
        if (IsLiked == 1) {
          await cancel_like_team(params);
        } else {
          await like_team(params);
        }
      }
      this.getList();
    },
    rouetDetail(id) {
      let url = '?id=' + id
      if (this.current == 1) {
        url = '/pool_detail' + url
      }
      if (this.current == 2) {
        url = '/item_detail' + url
      }
      if (this.current == 3) {
        url = '/team_detail' + url
      }
      this.$router.push(url);
    }
  },
};
</script>

<style scoped lang="scss">
.wrap {
  display: flex;
  flex-direction: column;
}

.banner {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.banner img {
  width: 867px;
}

.nav {
  position: absolute;
  width: 1000px;
  min-height: 105px;
  margin: 0 40px 0 40px;
  background-color: #1b1b1a;
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  border-radius: 20px;
}

.nav button {
  border: none;
  box-shadow: none;
  color: #ffffff;
  font-size: 36px;
  margin: 10px;
  background-color: #1b1b1a;
}

.nav .active {
  background-color: #030200;
  border-radius: 20px;
  color: #db9636;
}

.nav button:nth-child(1) {
  width: 300px;
}

.nav button:nth-child(2) {
  width: 320px;
}

.nav button:nth-child(3) {
  width: 320px;
}

.pool {
  flex: 1;
  height: 1px;
  overflow-y: auto;
  padding: 0 40px 0 140px;
  background-image: url(@/assets/image/14.png);
  background-position: 64px 40px;
  background-size: 32px 1113px;
  background-repeat: no-repeat;
  margin-top: 60px;
}

.list {
  width: 900px;
  height: 220px;
  background-color: #1e1e1d;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 40px 0 60px;
  box-sizing: border-box;
}

.list-left {
  display: flex;
}

.list-left img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 52px 37px 0 0;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 67px 0 69px 0;
}

.info span {
  color: #ffffff;
}

.name {
  font-size: 34px;
  height: 33px;
  line-height: 33px;
  font-weight: bold;
  vertical-align: top;
}

.num {
  font-size: 32px;
  height: 31px;
  line-height: 31px;
  vertical-align: baseline;
}

.num b:first-child {
  color: #979690;
  font-weight: normal;
}

.num b:last-child {
  color: #e28928;
  font-weight: normal;
}

.list-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 35px 41px 38px 0;
}

.liked {
  width: 250px;
  height: 82px;
  line-height: 82px;
  background-color: #353432;
  border-radius: 40px;
  display: flex;
}

.liked img {
  width: 46px;
  height: 44px;
  margin: 16px 19px 22px 37px;
}

.liked span {
  font-size: 28px;
  color: #a2a2a1;
}

.list-right .active {
  background-color: #382c1f;
}

.list-right .active span {
  color: #c28928;
}

.hot {
  display: flex;
  height: 42px;
}

.hot img {
  height: 36px;
}

.hot span {
  font-size: 34px;
  color: #ffffff;
  margin-left: 17px;
}
</style>
