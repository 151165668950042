<template>
  <div class="wrap">
    <div class="banner">
      <img src="../assets/image/1.png" alt="" />
      <div class="airdrop-nav">
        <div :class="{ active: activeIndex === 1 }" @click="activeIndex = 1">
          {{ $t('jinchanzi') }}
        </div>
        <div :class="{ active: activeIndex === 2 }" @click="activeIndex = 2">
          {{ $t('withdraw_list') }}
        </div>
      </div>
    </div>
    <div class="content">
      <div class="cicada-info" v-show="activeIndex === 1">
        <div class="cicada-data">
          <div>
            <span>{{ $t('yiwaqu') }} {{ reward[1] }}</span><span>{{ reward[2] }}</span>
          </div>
        </div>
        <!-- <div class="cicada-draw">
          <button>提取</button>
        </div> -->
      </div>
      <div class="draw-list" v-show="activeIndex === 2">
        <template v-if="drawList.length">
          <template v-for="(item, index) in drawList">
            <div class="token" :key="index">
              <div class="token-info">
                <div class="token-left">
                  <img :src="item.Avatar" />
                  <div class="token-text">
                    <div class="token-name">{{ item.Symbol }}</div>
                    <div class="token-address">{{ item.Address.substr(0, 6) }}...{{ item.Address.substr(-4) }}</div>
                  </div>
                </div>
                <div class="token-right">
                  <div>{{ item.MintReward }}</div>
                  <img v-if="!item.showDetail" src="../assets/image/26.png" @click="showDetail(index)" />
                  <img v-else src="../assets/image/27.png" @click="showDetail(index)" />
                </div>
              </div>
              <div class="draw-detail" v-if="item.showDetail">
                <div class="line"></div>
                <div class="mint-data">
                  <div><span>{{ $t('yiwaqu') }}</span><span>{{ item.MintReward }}</span></div>
                  <div><span>{{ $t('ketiqu') }}</span><span>{{ item.info && item.info[0] }}</span></div>
                  <div><span>{{ $t('yitiqu') }}</span><span>{{ item.info && item.info[1] }}</span></div>
                </div>
                <div class="mint-draw" :class="{ 'disabled': telegramUserId }" @click="getHandle(item.Address)">{{
                  $t('tiqu') }}</div>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="nohint">{{ $t('nodata') }}</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { get_main_reward, get_draw_list, get_user_reward, admin_set_reward } from "@/api/index";
import { Notify, Toast } from "vant";
import {
  drawReward
} from "@/contract";
export default {
  name: "AirDrop",
  data() {
    return {
      activeIndex: 2,
      reward: [],
      drawList: [],
      currentId: ''
    };
  },
  created() {
    this.getDrawList()
    this.getCoinTotal()
  },
  computed: {
    selectAddress() {
      return this.$store.state.user.accountAddress;
    },
    telegramUserId() {
      return this.$store.state.user.telegramUserId;
    }
  },
  methods: {
    getDrawList() {
      get_draw_list({
        address: this.selectAddress
      }).then(res => {
        if (res.data && res.data.length) {
          this.drawList = res.data.map(v => {
            v.showDetail = false
            return v
          })
        }

      })
    },

    async showDetail(index) {
      this.currentId = this.drawList[index].Id
      if (!this.drawList[index].info) {
        let info = await this.getDetail(index)
        this.$set(this.drawList[index], 'info', info)
      }
      this.$set(this.drawList[index], 'showDetail', !this.drawList[index].showDetail)
    },
    getDetail(index) {
      return new Promise(resolve => {
        get_user_reward({
          pool_id: this.currentId,
          address: this.selectAddress
        }).then(res => {
          resolve(res.data)
        })
      })

    },
    // 获取平台币
    getCoinTotal() {
      get_main_reward({
        address: this.selectAddress,
      }).then((res) => {
        console.log(res);
        this.reward = res.data || [];
      });
    },
    getHandle(address) {
      if (this.telegramUserId) {
        Notify({
          type: "warning",
          message: this.$t('tiqu_warning'),
        });
        return
      }
      Toast.loading({
        duration: 0,
        forbidClick: true,
      });
      admin_set_reward({
        token_address: address,
        user_address: this.selectAddress
      }).then(() => {
        setTimeout(() => {
          Toast.clear()
          drawReward(this.selectAddress, address).then(res => {
            Notify({
              type: "success",
              message: this.$t("success"),
            });
          })
        }, 5000);
      }).catch(() => {
        // Toast.clear()
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.banner {
  position: relative;
}

.banner img {
  width: 1080px;
}

.content {
  padding: 0 40px;
}

.airdrop-nav {
  position: absolute;
  bottom: 25px;
  margin: 0 40px;
  height: 105px;
  font-size: 36px;
  background-color: #1d1d1c;
  border-radius: 20px;
  display: flex;
  justify-content: center;
}

.airdrop-nav div {
  border: none;
  box-shadow: none;
  color: #ffffff;
  background-color: #1d1d1c;
  margin: 10px;
  width: 480px;
  height: 85px;
  line-height: 85px;
  text-align: center;
  border-radius: 20px;
  font-family: "PingFang-SC-Regular";
}

.airdrop-nav .active {
  color: #db9636;
  background-color: #030200;
}

.cicada-info {
  margin-top: 50px;
}

.cicada-data {
  color: #ffffff;
  background-color: #1d1d1c;
  border-radius: 60px;
  padding: 46px;
  font-size: 34px;
}

.cicada-data div {
  display: flex;
  justify-content: space-between;
  font-family: PingFang-SC-Regular;
  margin-bottom: 34px;
}

.cicada-data>div:last-child {
  margin-bottom: 0;
}

.cicada-draw {
  margin-top: 50px;
  background-color: #1d1d1c;
  border-radius: 60px;
  padding: 30px;
}

.cicada-draw button {
  background-color: #db9636;
  color: #ffffff;
  border-radius: 40px;
  width: 940px;
  height: 120px;
  font-size: 36px;
}

.draw-list {
  margin-top: 30px;
}

.token {
  width: 1000px;
  background-color: #21211f;
  border-radius: 60px;
  margin-bottom: 30px;
}

.token-info {
  display: flex;
  justify-content: space-between;
}

.token-left {
  display: flex;
}

.token-left img {
  width: 120px;
  height: 120px;
  margin: 43px 40px 37px 80px;
  border-radius: 50%;
}

.token-text {
  margin: 60px 0 66px 0;
}

.token-name {
  color: #ffffff;
  font-size: 38px;
  height: 38px;
  line-height: 38px;
}

.token-address {
  color: #7f7f7f;
  font-size: 34px;
  height: 34px;
  line-height: 34px;
  margin-top: 8px;
}

.token-right {
  display: flex;
  align-items: center;
  margin-right: 80px;
}

.token-right img {
  width: 62px;
  height: 50px;
  margin-left: 40px;
}

.token-right div {
  color: #db9636;
  font-size: 48px;
}

.draw-detail {
  overflow: hidden;
}

.line {
  margin: 0 73px 0 67px;
  border-bottom: 1px solid #403f3d;
}

.mint-data {
  margin: 46px 49px 77px 53px;
  color: #ffffff;
  font-size: 34px;
}

.mint-data div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;
}

.mint-data>div:last-child {
  margin-bottom: 0;
}

.mint-data div>span:last-child {
  font-weight: bold;
}

.mint-draw {
  width: 940px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  background-color: #db9636;
  color: #ffffff;
  font-size: 36px;
  font-weight: bold;
  border-radius: 40px;
  margin: 0 30px 60px 30px;

  &.disabled {
    background-color: rgba($color: #db9636, $alpha: 0.5);
  }
}
</style>
