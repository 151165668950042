import web3 from "web3";
import { get_user_info } from '@/api'

const user = {
  state: {
    accountAddress: "",
    userData: {
      id: 0
    },
    telegramUserId:''
  },

  mutations: {
    SET_ADDRESS: (state, address) => {
      state.accountAddress = address;
    },
    SET_USERDATA: (state, userData) => {
      state.userData = userData
    },
    SET_TELE_USER_ID:(state, userId) => {
      state.telegramUserId = userId
    },
  },

  actions: {
    loadUserData({ commit, state }) {
      return new Promise(async (resolve, reject) => {
        let data = await new Promise((resolve) => {
          get_user_info({ address: state.accountAddress }).then(res => {
            resolve(res.data)
          }).catch(err => reject())
        })
        commit('SET_USERDATA', data)
        resolve(data)
      })
    },
  },
};

export default user;
