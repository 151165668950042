<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  // 以vue2为例
  mounted() {
    
  },
};
</script>
<style lang="scss">
#app {
  font-family: "MyFont", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;
  background: linear-gradient(0deg, #050505, #1B1916);
}
</style>
