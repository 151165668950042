<template>
    <div class="wrap">
        <div class="title">{{ $t('toRecmond') }}</div>
        <div class="sub-title">{{ $t('toRecmond_tips') }}</div>
        <div class="input">
            <input type="text" placeholder="0x64E21647......C4dC1EB5D4" v-model="address" />
        </div>
        <div class="binding">
            <div class="binding-button" @click="bindingFriend()">{{ $t('toRecmond_add') }}</div>
        </div>
    </div>
</template>
<script>
import { Notify, Toast } from "vant";
import {
    binding_lower
} from '@/api'
export default {
    data() {
        return {
            address: ''
        };
    },
    computed: {
        selectAddress() {
            return this.$store.state.user.accountAddress;
        },
    },
    methods: {
        bindingFriend() {
            if (!this.address) {
                Notify({
                    type: "danger",
                    message: this.$t('toRecmond_warning'),
                });
                return
            }
            Toast.loading({
                duration: 0,
                forbidClick: true,
            });
            binding_lower({
                lower: this.address,
                address: this.selectAddress
            }).then(res => {
                Toast.clear();
                Notify({
                    type: "success",
                    message: this.$t('success'),
                });
            }).catch(err=>{
                Notify({
                    type: "warning",
                    message: err.msg,
                });
            })
        },
    },
}
</script>

<style scoped lang="scss">
.wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 40px 0 40px;
}

.title {
    color: #FFFFFF;
    margin: 0 auto;
    margin-top: 207px;
    font-size: 64px;
    font-weight: bold;
}

.sub-title {
    color: #979690;
    margin: 50px auto;
    line-height: 72px;
    font-size: 34px;
}

.input input {
    width: 930px;
    height: 120px;
    background-color: #1D1D1C;
    border-radius: 40px;
    font-size: 36px;
    color: #FFFFFF;
    padding-left: 70px;
}

.input input::placeholder {
    font-size: 36px;
}

.binding-button {
    width: 1000px;
    height: 130px;
    line-height: 130px;
    margin-top: 30px;
    text-align: center;
    background-color: #DB9636;
    border-radius: 40px;
    color: #FFFFFF;
    font-size: 36px;
    font-weight: bold;
}

.list-text {
    margin-top: 89px;
    color: #FFFFFF;
    font-size: 36px;
    font-weight: bold;
}

.friend-list {
    margin-top: 50px;
    width: 1000px;
    background-color: #1D1D1C;
    border-radius: 60px;
}

.friend {
    margin: 36px 61px 0 61px;
    display: flex;
    align-items: center;
}

.friend:last-child {
    margin-bottom: 36px;
}

.friend img {
    width: 84px;
    height: 84px;
}

.friend span {
    color: #FFFFFF;
    font-size: 34px;
    margin-left: 47px;
}

.invite {
    display: flex;
    justify-content: space-between;
    margin-top: 226px;
}

.invite-button {
    width: 842px;
    height: 130px;
    line-height: 130px;
    text-align: center;
    background-color: #DB9636;
    font-size: 36px;
    color: #FFFFFF;
    border-radius: 40px;
}

.copy-button {
    width: 140px;
    height: 130px;
    line-height: 130px;
    text-align: center;
    background-color: #DB9636;
    color: #FFFFFF;
    border-radius: 40px;
}

.copy-button img {
    width: 50px;
    height: 50px;
    vertical-align: middle;
    /* 垂直居中 */
}
</style>