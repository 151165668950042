import axios from 'axios'

import Vue from 'vue'
// import store from '../../store/store.js'
// loading效果
import errorCode from '@/utils/errorCode'
import { Toast } from 'vant'
import router from '../router/index.js'
Vue.use(Toast)
axios.defaults.headers['Content-Type'] =
  'application/x-www-form-urlencoded;charset=utf-8'
const request = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API
  baseURL: 'https://mintgame.mine123.site',
  // baseURL: 'http://192.168.2.208:8888'
})
let loadingInstance = null
request.interceptors.request.use(
  (config) => {
    // console.log(config)
    // const tokenStr = store.state.tokenInfo.token
    // if (tokenStr) {
    // 3. 添加身份认证字段
    //   config.headers.Authorization = `Bearer ${tokenStr}`
    // }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
request.interceptors.response.use(
  (res) => {
    // return response.data
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    if (code == '503') {
      //   Toast('无效的会话，或者会话已过期，请重新登录。')
      router.push('/503')
      return Promise.reject('系统维护中')
    } else if (code === 500) {
      Toast(res.data.msg)
      return Promise.reject(new Error(msg))
    } else if (code !== 200) {
      Toast(res.data.msg)
      return Promise.reject(res.data)
    } else {
      if (location.href.indexOf('503') > -1) {
        router.replace('/')
      }
      return res.data
    }
  },
  (error) => {
    // 如果有响应的结果，并且响应的状态码是 401，则证明 Token 过期了
    if (error.response && error.response.state === 401) {
      console.log('token过期了')
      Toast.loading({
        message: 'token过期啦',
        duration: 2000, // 展示时长(ms)，值为 0 时，toast 不会消失
        forbidClick: true,
      })

      // TODO1：清空 vuex 和 localStorage 中的数据
      // store.commit('clearToken')
      // TODO2：强制跳转到登录页，并通过路由的 query 查询参数，把当前用户访问未遂的路由地址带给登录页
      router.replace('/login?pre=' + router.currentRoute.fullPath)
    }
    return Promise.reject(error)
  }
)

export default request
