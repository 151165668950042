import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
import airdrop from '../views/airdrop.vue'
import home from '../views/home.vue'
import rank from '../views/rank.vue'
import user from '../views/user.vue'
import task from '../views/task.vue'
import binding from '../views/binding.vue'
import mainDetail from '../views/mainDetail.vue'
import poolDetail from '../views/poolDetail.vue'
import itemDetail from '../views/itemDetail.vue'
import teamDetail from '../views/teamDetail.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: 'home',
    children: [
      {
        path: 'airdrop',
        component: airdrop,
        name: 'airdrop',
      },  
      {
        path: 'home',
        component: home,
        name: 'home',
      },
      {
        path: 'rank',
        component: rank,
        name: 'rank',
      },
      {
        path: 'user',
        component: user,
        name: 'user',
      },
      {
        path: 'task',
        component: task,
        name: 'task',
      },    
    ],
  },
  {
    path: '/binding',
    component: binding,
    name: 'binding',
  },
  {
    path: '/main_detail',
    component: mainDetail,
    name: 'mainDetail',
  },
  {
    path: '/pool_detail',
    component: poolDetail,
    name: 'poolDetail',
  },
  {
    path: '/item_detail',
    component: itemDetail,
    name: 'itemDetail',
  },
  {
    path: '/team_detail',
    component: teamDetail,
    name: 'teamDetail',
  }
]

const router = new VueRouter({
  mode:'history',
  routes,
})

export default router
